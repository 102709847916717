import actionsTypes from '../actionTypes';

const defaultState = {
    userToken: "",
    userLoginSuccess: false,
    loginLoading: false,
    pathname: "/"
};

/**
 *
 *
 * @param {*} [state=defaultState]
 * @param {*} action
 * @returns
 */
const loginReducer = (state = defaultState, action) => {
    switch (action.type) {
        case actionsTypes.LOGIN_LOADING: {
            return {
                ...state,
                loginLoading: action.payload,
            };
        }
        case actionsTypes.LOGIN_SUCCESS: {
            return {
                ...state,
                userLoginSuccess: action.payload,
                loginLoading: false
            };
        }
        case actionsTypes.LOGIN_ERROR: {
            return {
                ...state,
                userLoginSuccess: action.payload,
                loginLoading: false
            };
        }
        case actionsTypes.SAVE_PATH_NAME: {
            return {
                ...state,
                pathname: action.payload
            }
        }
        default:
            return state;
    }
};

export default loginReducer;
