import { combineReducers } from 'redux';
import campaignReducer from './campaign.reducer';
import profileReducer from './profile.reducer';
import loginReducer from './login.reducer';
import websiteLanguageReducer from "./websiteLanguage.reducer"

export default combineReducers({
	campaign: campaignReducer,
	profile: profileReducer,
	login: loginReducer,
	websiteLanguage: websiteLanguageReducer
});
