import React, { PureComponent } from "react";
import { Image } from "react-bootstrap";
import { withRouter } from "react-router-dom";
class Product extends PureComponent {
  redirectToDetail = () => {
    // console.log('props', this.props);
    this.props.setProductDetail({
      ...this.props.productDetail,
    });
    this.props.history.push({
      pathname: `/productDetail/${this.props.media.imageId}`,
      // pathname: `/clientwebsiterevamp/productDetail/${this.props.media.imageId}`,

      state: {
        media: this.props.media,
        whatsappLink: this.props.whatsappLink,
        businessName: this.props.businessName,
      },
    });
  };
  render() {
    const { selected, dashboard } = this.props;
    return (
      <div
        onClick={() => {
          if (dashboard) {
            this.props.addToCart(this.props.media);
          } else this.redirectToDetail();
        }}
        key={this.props.media.imageId}
        className="productDetailContainer"
      >
        {selected ? (
          <Image className="notSelected" src="/assets/images/svgs/Error.svg" />
        ) : (
          <div className="emptyDiv"></div>
        )}
        <div
          className={`imageDiv ${selected ? "selectedImage" : ""} ${
            this.props.productLength === 1
              ? "wp-1"
              : this.props.productLength === 2
              ? "wp-2"
              : this.props.productLength === 3
              ? "wp-3"
              : this.props.productLength === 4
              ? "wp-4"
              : this.props.productLength === 5
              ? "wp-5"
              : "wp-all"
          }`}
        >
          <Image
            src={this.props.media.imageUrl}
            alt={this.props.media.productName}
          />
        </div>
        <h4>
          {/* Product Name */}
          {/* 1234567 901234 678 */}
          {this.props.media.productName ? this.props.media.productName : ""}
        </h4>

        <h3>
          {this.props.media.price ? (
            <div>
              <span>KD</span>
              {this.props.media.price}
            </div>
          ) : (
            ""
          )}
        </h3>
      </div>
    );
  }
}

export default withRouter(Product);
