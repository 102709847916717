

import React, { Component } from "react";
import privacyPolicyEn from "../data/privacyPolicyEn";

export default class Privacy extends Component {
  componentDidMount() {
    window.intercomSettings = {
      app_id: "k5yqpre9",
      hide_default_launcher: true
    };
    window.scrollTo(0, 0);
    var div = document.getElementById("privacyPolicy");
    if (div) {
      var pathname = new URL(window.location.href).host
      let privacyPolicyEnIn = privacyPolicyEn
      privacyPolicyEnIn = privacyPolicyEnIn.replace("client URL", pathname)
      div.insertAdjacentHTML("beforeend", privacyPolicyEnIn);

    }
  }
  render() {
    document.body.classList.remove("inner-page");
    return <div className="mainHome privacy" id="privacyPolicy"></div>;
  }
}
