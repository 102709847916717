// jshint loopfunc: true

import React from "react";
// import About from './components/about';
// import Feature from './components/feature';
// import Work from './components/work';
// import ScreenShot from './components/screenshot';
// import Team from './components/team';
// import Blog from './components/blog';
// import Price from './components/price';
// import Testimonial from './components/testimonial';
// import FAQ from './components/faq';
// import Contact from './components/contact';
// import Subscribe from './components/subscribe';
import Footer from "../components/footer";
// import { Link, withRouter } from 'react-router-dom';
//Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Product from "../components/MainPageProduct";
import * as actionCreators from "../store/actions";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import find from "lodash/find";
import ReactLoading from "react-loading";
import $ from "jquery";
import Dropdown from "react-bootstrap/Dropdown";
import { isMobile } from "react-device-detect";
import WhatsappIcon from "../../src/assets/images/svgs/whatsapp.svg";
// import OptimizeLogoIcon from  '../../src/assets/images/logo.png'

import isStringArabic from "../store/isStringArabic";
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cartList: [],
      counter: 0,
      posts: [],
      no_of_products_to_show: 60,
      productsToShowArray: [12, 24, 36, 48, 60],
    };
  }
  componentDidMount() {
    this.props.checkTokenExpired(this.props.history);
    if (isEmpty(this.props.instagramAccountInfo.brandName))
      this.props.getInstagramAccountDetail(this.props.history);

    if (
      this.props.instagramAccountInfo &&
      this.props.instagramAccountInfo.businessName
    ) {
      this.props.getHiddenWebProducts(
        this.props.instagramAccountInfo.businessName
      );
    }

    if (
      this.props.instagramAccountInfo &&
      this.props.instagramAccountInfo.insta_handle
    ) {
      this.props.getInstagramPostInitial(
        this.props.instagramAccountInfo.insta_handle
      );
    }
  }
  checkIfRecordExist = (newRecords, imageId) => {
    return newRecords.findIndex((img) => img.imageId === imageId) === -1;
  };
  componentDidUpdate(prevProps) {
    if (
      prevProps.instagramAccountInfo.insta_handle !==
      this.props.instagramAccountInfo.insta_handle
    ) {
      this.props.getInstagramPostInitial(
        this.props.instagramAccountInfo.insta_handle
      );
    }
    if (
      prevProps.instagramAccountInfo.businessName !==
      this.props.instagramAccountInfo.businessName
    ) {
      this.props.getHiddenWebProducts(
        this.props.instagramAccountInfo.businessName
      );
    }
    if (
      !prevProps.no_of_products_to_show &&
      this.props.no_of_products_to_show
    ) {
      this.setState({
        no_of_products_to_show: this.props.no_of_products_to_show,
      });
    }
    if (
      !isNull(this.props.instagramAccountInfo.snap_pixel_script) &&
      !isEmpty(this.props.instagramAccountInfo.snap_pixel_script) &&
      prevProps.instagramAccountInfo.snap_pixel_script !==
        this.props.instagramAccountInfo.snap_pixel_script
    ) {
      var embedCode = this.props.instagramAccountInfo.snap_pixel_script; // <script>//my js script code</script>
      $("head").append(embedCode);
    }

    if (
      prevProps.getWebProductsLoading &&
      !this.props.getWebProductsLoading &&
      !this.props.savingWebProducts
    ) {
      this.setState({
        cartList: this.props.products_to_hide_list,
        counter: this.props.products_to_hide_list.length + 1,
      });
    }

    // set updating new list
    if (
      prevProps.instagramPostList.length !== this.props.instagramPostList.length
    ) {
      // check if products_to_hide_list empty

      if (
        this.props.products_to_hide_list &&
        this.props.products_to_hide_list.length === 0
      ) {
        const list = this.props.instagramPostList;
        this.setState({
          posts: list,
        });
      } else {
        const newRecords = [];
        // for (var i = 0; i < this.props.products_to_hide_list && this.props.products_to_hide_list.length; i++) {
        //     newRecords.push(this.props.products_to_hide_list[i]);
        // }
        // console.log('newRecords', newRecords);

        // check if already in post

        for (var x = 0; x < this.props.instagramPostList.length; x++) {
          if (
            this.checkIfRecordExist(
              newRecords,
              this.props.instagramPostList[x].imageId
            )
          ) {
            newRecords.push(this.props.instagramPostList[x]);
          }
        }

        this.setState({
          posts: [...newRecords],
        });
      }
    }
  }
  handleSubmission = () => {
    this.props.saveWebProducts(
      this.props.instagramAccountInfo.businessid,
      this.state.cartList,
      this.props.products_to_hide_id,
      this.props.businessLogo,
      this.state.no_of_products_to_show
    );
  };
  addToCart = (item) => {
    const newCartList = [...this.state.cartList];
    const checkifALreadyExist = find(
      this.state.cartList,
      (imageId) => imageId === item.imageId
    );
    // console.log('checkifALreadyExist', checkifALreadyExist);
    if (!checkifALreadyExist) {
      newCartList.push(item.imageId);
      const counterNew = this.state.counter;
      this.setState({
        cartList: newCartList,
        counter: counterNew + 1,
      });
    } else {
      const index = newCartList.indexOf(checkifALreadyExist);

      // console.log('index', index);
      const counterNew = this.state.counter;

      newCartList.splice(index, 1);
      this.setState({
        cartList: newCartList,
        counter: counterNew - 1,
      });
    }
  };
  render() {
    document.body.classList.remove("inner-page");

    if (this.props.loadingInstaDetail) {
      return (
        <div className="outerDiv">
          {/* <Loader type="Puff" color="#00BFFF" height="100" width="100" /> */}

          <ReactLoading type="spin" color="#5410BF" />
        </div>
      );
    }
    return (
      <div className="outerDiv">
        <Row
          className="logout"
          onClick={() => this.props.logout(this.props.history)}
        >
          Logout
        </Row>
        <Container className="mainHome">
          <Container className="innerBlock1">
            <Row className="brandImageRow">
              <Image
                className="brandImage"
                src={
                  this.props.instagramAccountInfo.brandProfilePic ||
                  this.props.businessLogo
                }
              />
            </Row>
            <Row
              className="brandNameText"
              style={{
                fontFamily: isStringArabic(
                  this.props.instagramAccountInfo.brandName
                )
                  ? "Araboto-bold"
                  : "Poppins-bold",
              }}
            >
              {this.props.instagramAccountInfo.brandName}
            </Row>
            {this.props.instagramAccountInfo.brandName === "العجمان اليوم" && (
              <Row className="brandNameSubText">تغطيات مميزة . مقاطع حصرية</Row>
            )}

            <div className="socialMediaButtons">
              {this.props.instagramAccountInfo.youtube_handle && (
                <Col className="columnDiv">
                  <a
                    href={this.props.instagramAccountInfo.youtube_handle}
                    target="_blank"
                    rel="noopener noreferrer"
                    id="youtubelink"
                  >
                    <Image
                      className="socialButtonImages socialButtonLogo"
                      src="/assets/images/svgs/Youtube.svg"
                    />
                  </a>
                  {this.props.instagramAccountInfo.showIconButtonNames && (
                    <p className="socialMediaName">
                      {this.props.instagramAccountInfo.buttonYoutube}
                    </p>
                  )}
                </Col>
              )}
              {this.props.instagramAccountInfo.telephoneNumber && (
                <Col className="columnDiv">
                  <a
                    href={`tel:${this.props.instagramAccountInfo.telephoneNumber}`}
                    id="telephoneNumber"
                  >
                    <Image
                      className="socialButtonImages"
                      src="/assets/images/svgs/mobile-outline.svg"
                    />
                  </a>
                  {this.props.instagramAccountInfo.showIconButtonNames && (
                    <p className="socialMediaName">
                      {this.props.instagramAccountInfo.buttonPhone}
                    </p>
                  )}
                </Col>
              )}
              {this.props.instagramAccountInfo.insta_handle && (
                <Col className="columnDiv">
                  <a
                    href={this.props.instagramAccountInfo.instagramLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    id="instagramLink"
                    onClick={() => {
                      if (
                        !isNull(
                          this.props.instagramAccountInfo.snap_pixel_script
                        ) &&
                        !isEmpty(
                          this.props.instagramAccountInfo.snap_pixel_script
                        ) &&
                        this.props.instagramAccountInfo.snap_pixel_script
                      ) {
                        window.snaptr("track", "VIEW_CONTENT");
                      }
                      // if (isMobile) {
                      // 	window.open(this.props.instagramAccountInfo.instagramLink)
                      // } else {
                      // 	window.open(`https://www.instagram.com/${this.props.instagramAccountInfo.insta_handle}`)
                      // }

                      return true;
                    }}
                  >
                    <Image
                      className="socialButtonImages"
                      src="/assets/images/svgs/instagram.svg"
                    />
                  </a>
                  {this.props.instagramAccountInfo.showIconButtonNames && (
                    <p className="socialMediaName">
                      {this.props.instagramAccountInfo.buttonInstagram}
                    </p>
                  )}
                </Col>
              )}
              {this.props.instagramAccountInfo.snapchat_handle && (
                <Col>
                  <a
                    href={`https://www.snapchat.com/add/${this.props.instagramAccountInfo.snapchat_handle}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    id="snapchatLink"
                  >
                    <Image
                      className="socialButtonImages socialButtonLogo"
                      // src="/assets/images/svgs/snapchatGhost.svg"
                      src="/assets/images/snap-ghost.png"
                    />
                  </a>
                  {this.props.instagramAccountInfo.showIconButtonNames && (
                    <p className="socialMediaName">
                      {this.props.instagramAccountInfo.buttonSnapchat}
                    </p>
                  )}
                </Col>
              )}
              {this.props.instagramAccountInfo.twitter_handle && (
                <Col>
                  <a
                    href={`https://twitter.com/${this.props.instagramAccountInfo.twitter_handle}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    id="twitterLink"
                  >
                    <Image
                      className="socialButtonImages socialButtonLogo"
                      src="/assets/images/svgs/Twitter_Logo_WhiteOnImage.svg"
                    />
                  </a>
                  {this.props.instagramAccountInfo.showIconButtonNames && (
                    <p className="socialMediaName">
                      {this.props.instagramAccountInfo.buttonTwitter}
                    </p>
                  )}
                </Col>
              )}
              {this.props.instagramAccountInfo.googlemaplink && (
                <Col>
                  <a
                    href={
                      this.props.instagramAccountInfo.googlemaplink
                      // this.props.instagramAccountInfo.businessName === 'willcafe'
                      // 	? 'https://maps.app.goo.gl/q72cWJniwwvxGA5s9'
                      // 	: this.props.instagramAccountInfo.businessName === 'lampo'
                      // 	? 'https://goo.gl/maps/aegDT4rzUerq4Tkn9'
                      // 	: ''
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    id="googleMapLink"
                  >
                    <Image
                      className="socialButtonImages"
                      src="/assets/images/svgs/Map.svg"
                    />
                  </a>
                  {this.props.instagramAccountInfo.showIconButtonNames && (
                    <p className="socialMediaName">
                      {this.props.instagramAccountInfo.buttonMap}
                    </p>
                  )}
                </Col>
              )}
              {this.props.instagramAccountInfo.whatsappnumber && (
                <Col className="columnDiv">
                  <a
                    // href={this.props.instagramAccountInfo.whatsappLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    id="whatsappLink"
                    onClick={() => {
                      if (isMobile) {
                        window.open(
                          `whatsapp://send?phone=${this.props.instagramAccountInfo.whatsappnumber}`
                        );
                      } else {
                        window.open(
                          this.props.instagramAccountInfo.whatsappLink
                        );
                      }
                      return true;
                    }}
                  >
                    <Image className="socialButtonImages" src={WhatsappIcon} />
                  </a>
                  {this.props.instagramAccountInfo.showIconButtonNames && (
                    <p className="socialMediaName">
                      {this.props.instagramAccountInfo.buttonWhatsapp}
                    </p>
                  )}
                </Col>
              )}
              {this.props.instagramAccountInfo.businessName === "bonabeel" && (
                <Col className="columnDiv">
                  <a
                    href={"https://bonabeel.co/"}
                    target="_blank"
                    rel="noopener noreferrer"
                    id="websiteLink"
                  >
                    <Image
                      className="socialButtonImages socialButtonLogo websiteLogo"
                      src="/assets/images/svgs/website.svg"
                    />
                  </a>
                  {this.props.instagramAccountInfo.showIconButtonNames && (
                    <p className="socialMediaName arabic bonabeelRegText">
                      الإشتراك بالدورة
                    </p>
                  )}
                </Col>
              )}
            </div>

            <div className="selectDropDownDiv">
              <h4 className="maxProductsText">
                Select the maxium products you want to show{" "}
              </h4>
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {this.state.no_of_products_to_show}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {this.state.productsToShowArray.map((item) => (
                    <Dropdown.Item
                      key={item}
                      onSelect={() => {
                        this.setState({
                          no_of_products_to_show: item,
                        });
                      }}
                      active={item === this.state.no_of_products_to_show}
                    >
                      {item}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <Row className="errorMessageText">{this.props.errorMessage}</Row>
            <Row className="errorMessageText">
              Please select Images from below that you don't want to show
            </Row>
          </Container>
        </Container>
        {this.state.posts && this.state.posts.length > 0 && (
          <div style={{ width: "100%" }}>
            <div className="shopNowContainer">
              <h4
                style={{
                  fontFamily: isStringArabic(
                    this.props.instagramAccountInfo.web_heading_key_name
                  )
                    ? "Araboto-bold"
                    : "Poppins-bold",
                }}
              >
                {this.props.instagramAccountInfo.web_heading_key_name}
              </h4>
            </div>
            <Container className="productsListContainer">
              <Row className="productsListRow">
                {this.state.posts &&
                  this.state.posts.map((image) => (
                    <Product
                      key={image.imageId}
                      media={image}
                      businessName={
                        this.props.instagramAccountInfo.businessName
                      }
                      whatsappLink={
                        this.props.instagramAccountInfo.whatsappLink
                      }
                      selected={
                        this.state.cartList.filter(
                          (imageId) => imageId === image.imageId
                        ).length > 0
                      }
                      dashboard={true}
                      addToCart={this.addToCart}
                    />
                  ))}
              </Row>
            </Container>
            <Container className="bottomButtons">
              {/* To show view more button when loading images from instagram */}
              {this.props.instaEndCursor && this.props.instaHasNextPage && (
                <div
                  style={{
                    display: "flex",
                    alignSelf: "center",
                    background: "#f4f4f4",
                    justifyContent: "center",
                    marginTop: 10,
                    marginBottom: 10,
                    // paddingTop: 20,
                  }}
                >
                  <Button
                    disabled={this.props.savingWebProducts}
                    style={{
                      cursor: "pointer",
                      alignSelf: "center",
                      background: "transparent",
                      border: "1px solid #ff790a",
                      borderRadius: 50,
                      color: "#ff790a",
                      height: 40,
                      width: 200,
                      fontFamily: isStringArabic(
                        this.props.instagramAccountInfo.viewMore
                      )
                        ? "Araboto-bold"
                        : "Poppins-bold",
                    }}
                    onClick={() =>
                      this.props.loadMoreInstagramPostProfile(
                        this.props.instaHandleId,
                        this.props.instaEndCursor
                      )
                    }
                  >
                    {this.props.instagramAccountInfo.viewMore}
                  </Button>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  alignSelf: "center",
                  background: "#f4f4f4",
                  justifyContent: "center",
                  marginRight: 20,
                  marginLeft: 20,
                  marginTop: 10,
                  marginBottom: 10,
                  // paddingTop: 20,
                }}
              >
                <Button
                  disabled={this.props.savingWebProducts}
                  style={{
                    cursor: "pointer",
                    alignSelf: "center",
                    color: "white",
                    border: "1px solid #ff790a",
                    borderRadius: 50,
                    background: "#ff790a",
                    height: 40,
                    width: 200,
                    fontFamily: "Poppins-bold",
                  }}
                  onClick={() => this.handleSubmission()}
                  // onClick={() => this.props.loadMoreInstagramPost(this.props.instagramAccountInfo.id, this.props.instagramAccountInfo.end_cursor)}
                >
                  SUBMIT
                </Button>
              </div>
            </Container>
            {this.props.message && (
              <Row className="bottomMessage">{this.props.message}</Row>
            )}
          </div>
        )}
        {/* Navbar Section
				<nav className="navbar navbar-expand-lg  theme-nav fixed-top">
					<div className="container">
						<a className="navbar-brand" href={`${process.env.PUBLIC_URL}/`}>
							<img src={OptimizeLogoIcon} alt="logo" />
						</a>
						<button
							className="navbar-toggler"
							type="button"
							data-toggle="collapse"
							data-target="#mainmenu"
							aria-expanded="false"
							aria-label="Toggle navigation"
						>
							<span className="navbar-toggler-icon">
								<i className="fa fa-align-justify" aria-hidden="true" />
							</span>
						</button>
						<div className="collapse navbar-collapse" id="mainmenu">
							<ul className="navbar-nav ml-auto" id="mymenu">
								<li className="nav-item ">
									<a className="nav-link" href="#home">
										Home
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#about">
										about
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#feature">
										feature
									</a>
								</li>

								<li className="nav-item">
									<a className="nav-link" href="#screenshots">
										screenshot
									</a>
								</li>

								<li className="nav-item">
									<a className="nav-link" href="#team">
										team
									</a>
								</li>
								<li className="nav-item dropdown">
									<a
										href="#news"
										className="nav-link dropdown-toggle"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
									>
										Blog
									</a>
									<ul className="dropdown-menu">
										<li className="nav-item">
											<Link className="nav-link" to={`${process.env.PUBLIC_URL}/blog-list`}>
												blog list
											</Link>
										</li>
										<li className="nav-item">
											<Link className="nav-link" to={`${process.env.PUBLIC_URL}/blog-details`}>
												blog details
											</Link>
										</li>
										<li className="nav-item">
											<Link className="nav-link" to={`${process.env.PUBLIC_URL}/blog-leftside`}>
												leftsidebar
											</Link>
										</li>
										<li className="nav-item">
											<Link className="nav-link" to={`${process.env.PUBLIC_URL}/blog-rightside`}>
												rightsidebar
											</Link>
										</li>
										<li className="nav-item">
											<Link
												className="nav-link"
												to={`${process.env.PUBLIC_URL}/blog-left-sidebar`}
											>
												details leftsidebar
											</Link>
										</li>
										<li className="nav-item">
											<Link
												className="nav-link"
												to={`${process.env.PUBLIC_URL}/blog-right-sidebar`}
											>
												details rightsidebar
											</Link>
										</li>
									</ul>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#contact">
										contact us
									</a>
								</li>
								<li className="nav-item dropdown">
									<a
										className="nav-link dropdown-toggle"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
									>
										Other Page
									</a>
									<ul className="dropdown-menu">
										<li className="nav-item">
											<Link className="nav-link" to={`${process.env.PUBLIC_URL}/sign-in`}>
												Sign In
											</Link>
										</li>
										<li className="nav-item">
											<Link className="nav-link" to={`${process.env.PUBLIC_URL}/sign-up`}>
												Sign Up
											</Link>
										</li>
										<li className="nav-item">
											<Link className="nav-link" to={`${process.env.PUBLIC_URL}/forget-password`}>
												Forget Password
											</Link>
										</li>
										<li className="nav-item">
											<Link className="nav-link" to={`${process.env.PUBLIC_URL}/thank-you`}>
												Thank You
											</Link>
										</li>
										<li className="nav-item">
											<Link className="nav-link" to={`${process.env.PUBLIC_URL}/review`}>
												Review
											</Link>
										</li>
										<li className="nav-item">
											<Link className="nav-link" to={`${process.env.PUBLIC_URL}/faq`}>
												FAQ
											</Link>
										</li>
										<li className="nav-item">
											<Link className="nav-link" to={`${process.env.PUBLIC_URL}/coming-soon`}>
												Coming Soon
											</Link>
										</li>
										<li className="nav-item">
											<Link className="nav-link" to={`${process.env.PUBLIC_URL}/download`}>
												Download
											</Link>
										</li>
										<li className="nav-item">
											<Link className="nav-link" to={`${process.env.PUBLIC_URL}/request`}>
												Request Demo
											</Link>
										</li>
										<li className="nav-item">
											<Link className="nav-link" to={`${process.env.PUBLIC_URL}/404`}>
												404
											</Link>
										</li>
									</ul>
								</li>
							</ul>
						</div>
					</div>
				</nav> */}

        {/*Footer Component*/}
        <Footer
          history={this.props.history}
          translate={this.props.translate}
          imagesList={this.state.posts}
        />

        {/*tap-top*/}
        {/* <div className="tap-top">
					<div>
						<i className="fa fa-angle-up" aria-hidden="true" />
					</div>
				</div> */}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  saveWebProducts: (
    businessid,
    cartList,
    products_to_hide_id,
    businessLogo,
    no_of_products_to_show
  ) =>
    dispatch(
      actionCreators.saveWebProducts(
        businessid,
        cartList,
        products_to_hide_id,
        businessLogo,
        no_of_products_to_show
      )
    ),
  getHiddenWebProducts: (businessName) =>
    dispatch(actionCreators.getHiddenWebProducts(businessName)),
  logout: (history) => dispatch(actionCreators.logout(history)),
  checkTokenExpired: (history) =>
    dispatch(actionCreators.checkTokenExpired(history)),
  getInstagramPostInitial: (insta_handle) =>
    dispatch(actionCreators.getInstagramPostInitial(insta_handle)),
  getInstagramAccountDetail: (history) =>
    dispatch(actionCreators.getInstagramAccountDetail(history)),
  loadMoreInstagramPostProfile: (instaHandleId, instaEndCursor) =>
    dispatch(
      actionCreators.loadMoreInstagramPostProfile(instaHandleId, instaEndCursor)
    ),
});
const mapStateToProps = (state) => ({
  languages: state.campaign.languages,
  instagramAccountInfo: state.campaign.instagramAccountInfo,
  errorMessage: state.campaign.errorMessage,
  loadingInstaDetail: state.campaign.loadingInstaDetail,
  instagramPostList: state.profile.instagramPostList,
  instaEndCursor: state.profile.instaEndCursor,
  instaHasNextPage: state.profile.instaHasNextPage,
  instaHandleId: state.profile.instaHandleId,
  businessLogo: state.profile.businessLogo,
  products_to_hide_list: state.profile.products_to_hide_list,
  products_to_hide_id: state.profile.products_to_hide_id,
  getWebProductsLoading: state.profile.getWebProductsLoading,
  savingWebProducts: state.profile.savingWebProducts,
  message: state.profile.message,
  no_of_products_to_show: state.campaign.no_of_products_to_show,
  translate: state.websiteLanguage.translations,
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
