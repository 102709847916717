import axios from "axios";
// import qs from 'querystring';
import LocalizedStrings from "react-localization";
// import '../../index.scss';
import actionTypes, { baseURL } from "../actionTypes";
import englishStrings from "../data/englishStrings";
import arabicStrings from "../data/arabicStrings";

/**
 * @function
 *
 * @description
 * A function to set language of the website and get all the strings for the given language
 *
 *
 * @param {string} language OneOf [ar, en]
 * @return {Object} {language, translated strings}
 */
export const getLanguageListPOEdit = (language) => {
  return async (dispatch) => {
    const response = await axios.get(`${baseURL}webtranslation/${language}`);
    //TODO: Remove false from if condition
    if (response.data.success) {
      const terms = response.data.data.result.terms;
      if (terms.length > 0) {
        let modifierJson = {};
        terms.map((term) => {
          return (modifierJson[term.term] = term.translation.content);
        });

        let strings = new LocalizedStrings({
          [language]: modifierJson,
        });
        // document.body.setAttribute('dir', language === 'en' ? 'ltr' : 'rtl');
        // if (language === 'ar') {
        // 	document.body.classList.add('rtl-body');
        // } else {
        // 	document.body.classList.remove('rtl-body');
        // }
        strings.setLanguage(language);

        return dispatch({
          type: actionTypes.SET_LANGUAGE_LIST_POEDIT,
          payload: {
            translations: strings,
            language,
          },
        });
      }
    } else {
      let strings = new LocalizedStrings({
        [language]: language === "ar" ? arabicStrings : englishStrings,
      });
      strings.setLanguage(language);
      return dispatch({
        type: actionTypes.SET_LANGUAGE_LIST_POEDIT,
        payload: {
          translations: strings,
          language,
        },
      });
    }
  };
};
