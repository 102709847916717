import React from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Image } from "react-bootstrap";
// import MetaTags from 'react-meta-tags';
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import * as actionCreators from "../store/actions";
import Footer from "../components/footer";
import capitalize from "lodash/capitalize";
// import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from "react-loaders";
import isNull from "lodash/isNull";
import isEmpty from "lodash/isEmpty";
import $ from "jquery";
import { isAndroid, isIOS, isIOS13, isMobile } from "react-device-detect";
import isStringArabic from "../store/isStringArabic";
import CloseIcon from "../assets/svgs/back_arrow.svg";
import RoundVector from "../assets/svgs/RoundVector.svg";
class ProductDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      media: {},
      whatsappLink: "",
      businessName: "",
      zoomImage: false,
    };
  }
  UNSAFE_componentWillMount() {
    const location = this.props.location;
    // console.log('location', location);

    const productId = location.pathname.substring(
      location.pathname.lastIndexOf("/") + 1
    );
    // if (location.state && location.state.media) {
    // const whatsappMesageLink = location.state.whatsappLink;
    // console.log('location.state.media', location.state.media);
    // console.log('qs', qs.parse(location.search));
    // const result = qs.parse(location.search);
    //console.log("productId", productId);

    //if (location.state.media.shortcode) {
    this.props.getInstagramAccountDetail(this.props.history, productId);
    // } else {
    //   this.props.setProductDetail(location.state.media);
    // }
  }
  componentDidUpdate(prevProps) {
    if (
      !isNull(this.props.instagramAccountInfo.snap_pixel_script) &&
      !isEmpty(this.props.instagramAccountInfo.snap_pixel_script) &&
      prevProps.instagramAccountInfo.snap_pixel_script !==
        this.props.instagramAccountInfo.snap_pixel_script
    ) {
      var embedCode = this.props.instagramAccountInfo.snap_pixel_script; // <script>//my js script code</script>
      $("head").append(embedCode);
    }
  }
  componentDidMount() {
    this.props.getLanguageListPOEdit(
      this.props.language && this.props.language === "ar" ? "ar" : "en"
    );
    // console.log('state', this.props);
    // const location = this.props.location;
    // if (location.state && location.state.media) {
    // const whatsappMesageLink = location.state.whatsappLink;
    // console.log('location.state.media', location.state.media);
    // console.log('qs', qs.parse(location.search));
    // const result = qs.parse(location.search);
    // this.props.getInstagramAccountDetail(this.props.history, result.productId);
    // const productMessage =
    // 	encodeURIComponent(location.state.media.imageUrl) +
    // 	`\n \n Hi there.\
    // The ${location.state.media.imageUrl} looks great!\
    // Is it available for delivery?`;
    // // TODO: `replace this link in state when actual product name comes up
    // const newWhatsappLink =
    // 	whatsappMesageLink +
    // 	`&text=https://${location.state.businessName}.optimizeapp.com/productDetail/${
    // 		location.state.media.imageId
    //     }`;
    // const newWhatsappLink =
    // 	whatsappMesageLink + `&text=http://192.168.8.110:3000/productDetail/${location.state.media.imageId}`;
    // console.log('new link', newWhatsappLink);
    // this.setState({
    // 	media: location.state.media,
    // 	whatsappLink: whatsappMesageLink,

    // 	// whatsappLink: newWhatsappLink,
    // 	businessName: location.state.businessName,
    // });
    // }
    window.scrollTo(0, 0);
  }
  goToPreviousPage = () => {
    this.props.history.goBack();
  };

  goToWhatsApp = () => {
    // msg: ===>  Hi there.The product looks great! Is it available for delivery ?

    console.log(
      "this.props.instagramAccountInfo.button_action",
      this.props.instagramAccountInfo.button_action
    );

    if (
      this.props.instagramAccountInfo.button_action?.toLowerCase() ===
      "whatsapp"
    ) {
      let whatsappLink = "";
      if (this.props.productDetail.shortcode) {
        whatsappLink = encodeURI(
          this.props.instagramAccountInfo.whatsappLink +
            `&text=https://www.instagram.com/p/${this.props.productDetail.shortcode} \t\t         
        ${this.props.instagramAccountInfo.button_message}`
        );
      } else {
        whatsappLink = encodeURI(
          this.props.instagramAccountInfo.whatsappLink +
            `&text=${window.location.href} \t\t         
        ${this.props.instagramAccountInfo.button_message}`
        );
      }

      console.log("whatsappLink", whatsappLink);

      //console.log('whatsappLink', this.props.instagramAccountInfo.whatsappLink);
      //if (isMobile) {
      window.open(whatsappLink);
      //} else {
      //window.open(this.props.instagramAccountInfo.whatsappLink)
      //}

      //window.open(whatsappLink, '_blank');
    } else {
      if (
        !isNull(this.props.instagramAccountInfo.snap_pixel_script) &&
        !isEmpty(this.props.instagramAccountInfo.snap_pixel_script) &&
        this.props.instagramAccountInfo.snap_pixel_script
      ) {
        window.snaptr("track", "VIEW_CONTENT");
      }
      if (
        this.props.instagramAccountInfo.button_action === "instagram" &&
        this.props.productDetail.shortcode === ""
      ) {
        window.open(
          `https://www.instagram.com/${this.props.instagramAccountInfo.insta_handle}/`,
          "_blank"
        );
      } else if (isMobile && isAndroid) {
        window.open(
          `instagram://media?id=${this.props.productDetail.shortcode}`,
          "_blank"
        );
      } else if (
        isMobile &&
        (isIOS || isIOS13) &&
        this.props.productDetail.shortcode != undefined
      ) {
        // window.open(`instagram://media?id=${this.props.productDetail.imageId}`, '_blank')
        window.open(
          `https://www.instagram.com/p/${this.props.productDetail.shortcode}/`,
          "_blank"
        );
      } else {
        if (this.props.productDetail.shortcode != undefined) {
          window.open(
            `https://www.instagram.com/p/${this.props.productDetail.shortcode}/`,
            "_blank"
          );
        } else {
          window.open(
            `https://www.instagram.com/${this.props.instagramAccountInfo.insta_handle}/`,
            "_blank"
          );
        }
      }
    }
    // if (
    // 	this.props.instagramAccountInfo.instagram_campaign_enabled === '1' ||
    // 	!this.props.instagramAccountInfo.whatsappnumber ||
    // 	this.props.instagramAccountInfo.businessName === 'thecoffeeworkshop'
    // ) {
    // 	window.open(`https://www.instagram.com/p/${this.props.productDetail.shortcode}/`, '_blank');
    // }
  };

  onImgLoad = ({ target: img }) => {
    if (img.offsetWidth > img.offsetHeight) {
      this.setState({
        zoomImage: true,
      });
    }
  };
  characterLimit = () => {
    let description = "";
    if (
      !this.props.loadingInstaDetail &&
      this.props.productDetail &&
      this.props.productDetail.productDescription &&
      this.props.productDetail.productDescription.length > 250
    ) {
      description =
        this.props.productDetail.productDescription.substring(0, 249) + "...";
    } else {
      description = this.props.productDetail.productDescription;
    }
    return description;
  };
  render() {
    document.body.classList.remove("inner-page");

    const translate = this.props.translate;

    // console.log('this.props.productDetail', this.props.productDetail);

    // if (isMobile) {
    // 	const element = document.getElementsByClassName('innerBlockProduct');
    // 	if (!isEmpty(element)) {
    // 		console.log('elem', element);

    // 		element[0].style.maxHeight = `calc(100vh - 200px))`;
    // 	}
    // }
    const { button_action } = this.props.instagramAccountInfo;

    return (
      <div className="outerDivProductDetail">
        <Helmet>
          <title>OptimizeApp</title>
          <link
            rel="canonical"
            href={`https://${this.props.instagramAccountInfo.businessName}.optimizeapp.com/productDetail/${this.props.productDetail.imageId}`}
          />
          {/* <link
						rel="canonical"
						href={`http://192.168.8.110:3000/productDetail/${this.state.media.imageId}`}
					/> */}
          <meta
            name="og:title"
            itemProp="title"
            content={capitalize(this.props.instagramAccountInfo.businessName)}
          />
          <meta
            name="og:description"
            itemProp="description"
            content={this.props.productDetail.productDescription}
          />
          {/* <meta property="og:type" content="article" /> */}
          <meta
            property="og:image"
            itemProp="image"
            content={this.props.productDetail.imageUrl}
          />
          {/* <meta property="og:image" content={''} /> */}

          <meta
            property="og:url"
            itemProp="url"
            content={`https://${this.props.instagramAccountInfo.businessName}.optimizeapp.com/productDetail/${this.props.productDetail.imageId}`}
          />
          {/* <meta
						property="og:url"
						content={`http://192.168.8.110:3000/productDetail/${this.state.media.imageId}`}
					/> */}
        </Helmet>
        <Image className="vectorTop" src={RoundVector} />
        <Image className="vectorBottom" src={RoundVector} />
        <Container className="mainHome">
          <Container
            className="innerBlockProduct"
            style={this.props.loadingInstaDetail ? { height: "50%" } : {}}
          >
            <Image
              className="closeButton"
              src={CloseIcon}
              onClick={this.goToPreviousPage}
            />
            <div className="prodHeader">
              <div className="brandImageRowProduct">
                <Image
                  className="brandImage"
                  src={this.props.instagramAccountInfo.brandProfilePic}
                  onError={this.errorLoadingPicture}
                />
              </div>
              <div
                className="brandNameTextNewProd"
                style={{
                  fontFamily: isStringArabic(
                    this.props.instagramAccountInfo.brandName
                  )
                    ? "Araboto-bold"
                    : "Poppins-bold",
                }}
              >
                {this.props.instagramAccountInfo.brandName}
              </div>
            </div>
            <Row
              className="productImageRow"
              style={
                this.props.loadingInstaDetail
                  ? { alignItems: "center", justifyContent: "center" }
                  : {
                      width: isMobile ? window.innerWidth : 475,
                    }
              }
            >
              {this.props.loadingInstaDetail &&
              !this.props.productDetail?.imageUrl ? (
                <Loader
                  innerClassName="productDetailImage"
                  active={this.props.loadingInstaDetail}
                  type="ball-scale"
                />
              ) : (
                <Image
                  className={`productDetailImage ${
                    this.state.zoomImage && "zoomImage"
                  }`}
                  // style={{
                  // 	width: window.innerWidth
                  // }}
                  onLoad={this.onImgLoad}
                  src={this.props.productDetail.imageUrl}
                  alt={this.props.productDetail.productName}
                />
              )}
            </Row>
            <h5 className="productAmount">
              {!this.props.loadingInstaDetail &&
                this.props.productDetail.price &&
                this.props.productDetail.price && (
                  <div>
                    <span>KD</span>
                    {this.props.productDetail.price}
                  </div>
                )}
            </h5>
            <p
              className="productDescription"
              // Uncomment this styling  if needed
              // style={{ fontFamily: isStringArabic(this.props.productDetail.productDescription) ? "Araboto" : "Montserrat-regular" }}
            >
              {this.props.loadingInstaDetail &&
              !this.props.productDetail.productDescription
                ? ""
                : this.characterLimit()}
            </p>
          </Container>
        </Container>
        <div
          id="shopnow"
          className="orderButton shopNowContainerProd"
          onClick={() => {
            console.log(
              "clicked",
              this.props.instagramAccountInfo.button_action
            );
            if (button_action.toLowerCase() === "call") {
              document.location.href = `tel:${this.props.instagramAccountInfo.telephoneNumber}`;
            }
            if (
              button_action.toLowerCase() === "snapchat" ||
              this.props.pathname === "/snap"
            ) {
              window.open(
                `https://www.snapchat.com/add/${this.props.instagramAccountInfo.snapchat_handle}`
              );
            }
            if (button_action.toLowerCase() === "redirectToWebsite") {
              window.open(this.props.instagramAccountInfo.websitelink);
            }
            if (
              button_action.toLowerCase() === "instagram"
              // &&
              // !this.props.productDetail.shortcode
            ) {
              this.goToWhatsApp();
            }
            if (
              button_action.toLowerCase() !== "redirectToWebsite" &&
              button_action.toLowerCase() !== "call" &&
              button_action.toLowerCase() !== "snapchat"
              // &&
              // this.props.productDetail.shortcode
            ) {
              this.goToWhatsApp();
            }
          }}
        >
          <h4
            className="productName"
            style={{
              fontFamily: isStringArabic(
                translate[
                  this.props.instagramAccountInfo.web_heading_key_name.toUpperCase()
                ]
              )
                ? "Araboto-bold"
                : "Poppins-bold",
            }}
          >
            {/* Product Name */}

            {/* {!this.props.loadingInstaDetail &&
							this.props.productDetail &&
							this.props.productDetail.productName
							? this.props.productDetail.productName
							: this.props.instagramAccountInfo.googlemaplink ||
								this.props.instagramAccountInfo.businessName === 'thecoffeeworkshop'
								? 'Explore'
								: 'Shop Now'} */}
            {!this.props.loadingInstaDetail &&
            this.props.productDetail &&
            this.props.productDetail.productName
              ? this.props.productDetail.productName
              : translate[
                  this.props.instagramAccountInfo.web_heading_key_name.toUpperCase()
                ]}
          </h4>
        </div>
        {/* <Container className="productInfoContainer">
          <h5 className="productAmount">
            {!this.props.loadingInstaDetail &&
              this.props.productDetail.price &&
              this.props.productDetail.price && (
                <div>
                  <span>KD</span>
                  {this.props.productDetail.price}
                </div>
              )}
          </h5>
          <p
            className="productDescription"
            // Uncomment this styling  if needed
            // style={{ fontFamily: isStringArabic(this.props.productDetail.productDescription) ? "Araboto" : "Montserrat-regular" }}
          >
            {this.props.loadingInstaDetail ? "" : this.characterLimit()}
          </p>

          <button
            variant="secondary"
            disabled={
              this.props.loadingInstaDetail ||
              //  || !this.props.productDetail.shortcode
              !this.props.productDetail.imageId
            }
            className="orderButton"
            id={"shopnow"}
            onClick={() => {
              console.log("clicked");
              if (button_action === "call") {
                document.location.href = `tel:${this.props.instagramAccountInfo.telephoneNumber}`;
              }
              if (
                button_action === "snapchat" ||
                this.props.pathname === "/snap"
              ) {
                window.open(
                  `https://www.snapchat.com/add/${this.props.instagramAccountInfo.snapchat_handle}`
                );
              }
              if (button_action === "redirectToWebsite") {
                window.open(this.props.instagramAccountInfo.websitelink);
              }
              if (
                this.props.instagramAccountInfo.button_action === "instagram" &&
                !this.props.productDetail.shortcode
              ) {
                this.goToWhatsApp();
              }
              if (
                this.props.instagramAccountInfo.button_action !==
                  "redirectToWebsite" &&
                this.props.instagramAccountInfo.button_action !== "call" &&
                this.props.instagramAccountInfo.button_action !== "snapchat" &&
                this.props.productDetail.shortcode
              ) {
                this.goToWhatsApp();
              }
            }}
            style={{
              fontFamily: isStringArabic(
                translate[this.props.instagramAccountInfo.button_text]
              )
                ? "Araboto-bold"
                : "Poppins-bold",
            }}
          >
            <Image
              src={`/assets/images/svgs/${
                this.props.pathname === "/snap"
                  ? "snapchat-outline"
                  : this.props.instagramAccountInfo.button_icon
              }.svg`}
            />
            {this.props.pathname === "/snap"
              ? "المشاهدة"
              : translate[this.props.instagramAccountInfo.button_text]}
          </button>
        </Container> */}
        {/* <Footer
          history={this.props.history}
          translate={this.props.translate}
          imagesList={this.props.instagramAccountInfo.imagesList}
        /> */}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getInstagramAccountDetail: (history, productId) =>
    dispatch(actionCreators.getInstagramAccountDetail(history, productId)),
  getLanguageListPOEdit: (language) =>
    dispatch(actionCreators.getLanguageListPOEdit(language)),
  setProductDetail: (productDetail) =>
    dispatch(actionCreators.setProductDetail(productDetail)),
});
const mapStateToProps = (state) => ({
  language: state.websiteLanguage.language,
  languages: state.campaign.languages,
  instagramAccountInfo: state.campaign.instagramAccountInfo,
  productDetail: state.campaign.instagramAccountInfo.productDetail,
  errorMessage: state.campaign.errorMessage,
  loadingInstaDetail: state.campaign.loadingInstaDetail,
  pathname: state.login.pathname,
  translate: state.websiteLanguage.translations,
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductDetail)
);
