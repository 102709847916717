import React from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { Row, Image } from 'react-bootstrap'

import * as actionCreators from "../store/actions"
import OptimizeLogoIcon from  '../assets/images/logo.png'


class SignIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            weburl: ""
        }
    }
    async componentDidMount() {
        this.props.checkTokenExpired(this.props.history)
        this.props.getInstagramAccountDetail(this.props.history)
    }
    setValue = (name, value) => {
        this.setState({
            [name]: value
        })
    }
    render() {
        document.body.classList.remove('inner-page');

        return (
            <section className="auth-page sectionStyle" >
                <Row className="logo">
                    <Image className="logoImage" src={OptimizeLogoIcon} />
                </Row>
                <div >
                    <div className="animation-circle-inverse">
                        <i></i>
                        <i></i>
                        <i></i>
                    </div>
                    <div className="animation-circle">
                        <i></i>
                        <i></i>
                        <i></i>
                    </div>
                    <div className="auth-card">
                        <div className="text-center">
                            <h2>Sign In</h2>
                            <img src="assets/images/title-line.png" alt="title-line" className="img-fluid line" />
                            <p>Welcome To OptimizeApp Admin Panel, Please Sign in With Your Business Account Information.</p>
                        </div>
                        <div className="main">
                            <div className="auth-form">
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Email</label>
                                    <i className="fa fa-envelope-o"></i>
                                    <input
                                        onChange={(e) => {
                                            this.setValue('email', e.target.value)
                                        }}
                                        value={this.state.email}
                                        required
                                        type="email"
                                        className="form-control"
                                        id="exampleInputEmail1"
                                        placeholder="Email" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">Password</label>
                                    <i className="fa fa-lock"></i>
                                    <input
                                        onChange={(e) => {
                                            this.setValue('password', e.target.value)
                                        }}
                                        value={this.state.password}
                                        id="exampleInputPassword1"
                                        required
                                        type="password"
                                        className="form-control"
                                        placeholder="Password" />
                                </div>
                                <div className="form-group">
                                    <button className="btn submit" onClick={() => {
                                        this.props.login(this.state.email, this.state.password, this.props.instagramAccountInfo.businessName, this.props.history)
                                    }}>Sign in</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    login: (email, password, weburl, history) => dispatch(actionCreators.login(email, password, weburl, history)),
    checkTokenExpired: (history) => dispatch(actionCreators.checkTokenExpired(history)),
    getInstagramAccountDetail: (history, productId) =>
        dispatch(actionCreators.getInstagramAccountDetail(history, productId)),
});
const mapStateToProps = state => ({
    languages: state.campaign.languages,
    instagramAccountInfo: state.campaign.instagramAccountInfo,
    productDetail: state.campaign.instagramAccountInfo.productDetail,
    errorMessage: state.campaign.errorMessage,
    loadingInstaDetail: state.campaign.loadingInstaDetail,
});
export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SignIn)
);
