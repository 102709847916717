const enJSon = {
  EXPLORE: "EXPLORE",
  "SHOP NOW": "SHOP NOW",
  "BOOK NOW": "BOOK NOW",
  "VIEW MORE": "VIEW MORE",
  "ORDER ON WHATSAPP": "ORDER ON WHATSAPP",
  Phone: "Phone",
  WhatsApp: "WhatsApp",
  Instagram: "Instagram",
  Snapchat: "Snapchat",
  Twitter: "Twitter",
  Youtube: "Youtube",
  "CHECKOUT OUR INSTAGRAM": "CHECK OUT OUR INSTAGRAM",
  "Powered by": "Powered by",
  "Get yours now!": " Get yours now!",
  "This site is Powered by Optimize App":
    "This site is Powered by Optimize App. ",
  "بو نبيل": "بو نبيل",
  "SHOP ON INSTAGRAM": "SHOP ON INSTAGRAM",
  "ORDER ON INSTAGRAM": "ORDER ON INSTAGRAM",
  "FOR INQUIRIES & ORDERS": "FOR ORDERS & INQUIRIES",
  "FOR RESERVATIONS & ORDERS": "FOR RESERVATION & INQUIRY",
  "CHECK OUT ON YOUTUBE": "CHECK OUT ON YOUTUBE",
  "CHECK OUT ON INSTAGRAM": "CHECK OUT ON INSTAGRAM",
  "CHECK OUT ON SNAPCHAT": "CHECK OUT ON SNAPCHAT",
  "Popular Items": "Popular Items",
  Featured: "Featured",
  PRICE: "PRICE",
  "Contact us for prices": "Contact us for prices",
  KWD: "KWD",
  AED: "AED",
  SAR: "SAR",
  BHD: "BHD",
  QAR: "QAR",
  OMR: "OMR",
  Map: "Map",
  "Shopping Cart": "Shopping Cart",
  "Total :": "Total :",
  "Proceed to checkout": "Proceed to checkout",
  "ADD TO CART": "ADD TO CART",
  Remove: "Remove",
  ADDRESS: "ADDRESS",
  AREA: "AREA",
  "Select Area": "Select Area",
  "PHONE NUMBER": "PHONE NUMBER",
  BLOCK: "BLOCK",
  STREET: "STREET",
  AVENUE: "AVENUE",
  "BUILDING/HOUSE": "BUILDING/HOUSE",
  OFFICE: "OFFICE",
  "Hey, it feels so light": "Hey, it feels so light.",
  "There is nothing in your cart": "There is nothing in your cart",
  Calendar: "Calendar",
  "Event Reminder": "Add to Calendar",
  Tiktok: "TikTok",
  TikTok: "TikTok",
  Facebook: "Facebook",
  "Privacy Policy": "Privacy Policy",
  "Connect with us": "Connect with us",
  "Google Map": "Google Map",
  Website: "Website",
  "VISIT STORE": "Visit Store",
  "Contact us": "Contact us",
  "View Details": "View Details",
  "VIEW DETAILS": "VIEW DETAILS",
};

export default enJSon;
