import axios from 'axios';
import actionTypes, { baseURL } from "../actionTypes";
import { loadInstagramPostBasedOnQuery } from "./campaign.action"

export const getInstagramPostInitial = insta_handle => {
    // console.log("insta_handle", insta_handle);

    return async dispatch => {
        try {
            dispatch({
                type: actionTypes.SET_INSTAGRAM_POST_LOADING,
                payload: true
            });
            // console.log('getInstagramPost insta_handle', insta_handle);
            var response = await axios.get(
                `https://www.instagram.com/${insta_handle}`
            );
            if (response && response.data) {
                var data = response.data;
                data = data.split("window._sharedData = ");
                data = data[1].split("</script>");
                data = data[0];
                data = data.substr(0, data.length - 1);
                data = JSON.parse(data);

                data = data.entry_data.ProfilePage[0].graphql.user;
                // console.log('data', data);
                var businessLogo = data.profile_pic_url;
                const mediaArray = [];

                const mediaList = data.edge_owner_to_timeline_media;
                // console.log("mediaList", mediaList);
                mediaArray.push(...mediaList.edges);
                let hasNextPage = mediaList.page_info.has_next_page;
                let end_cursor = mediaList.page_info.end_cursor;
                // console.log("mediaArrayLength", mediaArray.length);

                if (mediaArray && mediaArray.length > 0) {
                    var imagesList = mediaArray.map(media => {
                        // console.log('media', media);
                        // if (!media.node.is_video)
                        return {
                            imageUrl: media.node.display_url,
                            shortcode: media.node.shortcode,
                            imageId: media.node.id,
                            productDescription:
                                media.node.edge_media_to_caption.edges.length > 0
                                    ? media.node.edge_media_to_caption.edges[0].node.text
                                    : "",
                            isVideo: media.node.is_video
                        };
                    });

                    // imagesList = imagesList.filter(item => {
                    //     return !item.isVideo;
                    // });
                    // To make the list length as 12
                    while ((imagesList.length % 12 !== 0 || imagesList.length === 0) && hasNextPage && end_cursor) {
                        const newList = await loadInstagramPostBasedOnQuery(12 - (imagesList.length % 12), data.id, end_cursor, []);
                        end_cursor = newList.end_cursor
                        hasNextPage = newList.hasNextPage
                        imagesList = [...imagesList, ...newList.imagesList]
                    }

                    return dispatch({
                        type: actionTypes.SET_INSTAGRAM_POST,
                        payload: {
                            businessLogo: businessLogo,
                            imagesList: imagesList,
                            instaHandleId: data.id,
                            instaHasNextPage: hasNextPage,
                            instaEndCursor: end_cursor
                        }
                    });
                    // console.log('imageListAfterSize', imagesList.length);
                }

                return dispatch({
                    type: actionTypes.SET_INSTAGRAM_POST,
                    payload: {
                        businessLogo: "",
                        imagesList: [],
                        instaHandleId: null,
                        instaHasNextPage: null,
                        instaEndCursor: null
                    }
                });
            }
        } catch (error) {
            // console.log("insta error", error.response || error.message);
            return dispatch({
                type: actionTypes.ERROR_GET_INSTAGRAM_POST,
                payload: {
                    error: true,
                    errorMessage: null
                }
            });
        }
    };
};


// For HomePage loading more instagram post
export const loadMoreInstagramPostProfile = (instaHandleId, instaEndCursor) => {
    return async dispatch => {
        try {
            // dispatch({
            //   type: actionTypes.LOADING_MORE_INSTAGRAM_POST,
            //   payload: true
            // });
            const responseMedia = await axios.get(
                `https://www.instagram.com/graphql/query/?query_id=17888483320059182&variables={"id":"${instaHandleId}","first":12,"after":"${instaEndCursor}"}`
            );
            // console.log("responseMediA", responseMedia.data);

            let mediaArray = [
                ...responseMedia.data.data.user.edge_owner_to_timeline_media.edges
            ];

            let hasNextPage =
                responseMedia.data.data.user.edge_owner_to_timeline_media.page_info
                    .has_next_page;
            let end_cursor =
                responseMedia.data.data.user.edge_owner_to_timeline_media.page_info
                    .end_cursor;

            if (mediaArray && mediaArray.length > 0) {
                var imagesList = mediaArray.map(media => {
                    return {
                        imageUrl: media.node.display_url,
                        shortcode: media.node.shortcode,
                        imageId: media.node.id,
                        productDescription:
                            media.node.edge_media_to_caption.edges.length > 0
                                ? media.node.edge_media_to_caption.edges[0].node.text
                                : "",
                        isVideo: media.node.is_video
                    };
                });

                // imagesList = imagesList.filter(item => {
                //     return !item.isVideo;
                // });
                // To make the list length as 12
                while ((imagesList.length % 12 !== 0 || imagesList.length === 0) && hasNextPage && end_cursor) {
                    const newList = await loadInstagramPostBasedOnQuery(12 - (imagesList.length % 12), instaHandleId, end_cursor, []);
                    end_cursor = newList.end_cursor
                    hasNextPage = newList.hasNextPage
                    imagesList = [...imagesList, ...newList.imagesList]
                }
                return dispatch({
                    type: actionTypes.GET_MORE_INSTAGRAM_POST_PROFILE,
                    payload: {
                        imagesList: imagesList,
                        instaHasNextPage: hasNextPage,
                        instaEndCursor: end_cursor
                    }
                });
            }
        } catch (error) {
            // console.log("ERROR LOADING MORE", error.message || error.response);

            return dispatch({
                type: actionTypes.ERROR_GET_MORE_INSTAGRAM_POST_PROFILE,
                payload: {
                    imagesList: [],
                    instaHasNextPage: null,
                    instaEndCursor: null
                }
            });
        }
        // console.log('imageListAfterSize', imagesList.length);
    };
};


// GET LIST OF HIDDEN WEBPRODCUTS
export const getHiddenWebProducts = (business_url) => {
    return async dispatch => {
        try {
            dispatch({
                type: actionTypes.SET_LOADING_GET_WEB_PRODUCTS,
                payload: true
            })
            const response = await axios.get(`${baseURL}webProducts/${business_url}`);
            if (response && response.data) {
                const data = response.data;
                if (data.success) {
                    var id = data.productsinfo.id
                    var webProducts = JSON.parse(data.productsinfo.webproducts)
                    return dispatch({
                        type: actionTypes.SET_HIDDEN_INSTAGRAM_POST,
                        payload: {
                            products_to_hide_id: id,
                            products_to_hide_list: webProducts && webProducts.length > 0 ? webProducts : []
                        }
                    })
                }
                return dispatch({
                    type: actionTypes.SET_HIDDEN_INSTAGRAM_POST,
                    payload: {
                        products_to_hide_id: null,
                        products_to_hide_list: []
                    }
                })
            }

        } catch (error) {
            return dispatch({
                type: actionTypes.ERROR_SET_HIDDEN_INSTAGRAM_POST,
                payload: null
            })

        }
    }
}





/**
 * 
 * @param {*} businessid 
 * @param {Array} cartList 
 * @param {*} productInfoId  not present for the first time
 * @param {*} businessLogo updated each time user submits
 *  
 * 
 * SAVE PRODUCTS NOT TO BE SHOWN
 */
export const saveWebProducts = (
    businessid,
    cartList,
    productInfoId,
    businessLogo,
    no_of_products_to_show
) => {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.SAVE_WEB_PRODUCTS_LOADING,
            payload: true
        });
        // if productInfoId doesn't exist
        if (!productInfoId) {
            axios
                .post(`${baseURL}webProducts`, {
                    businessid,
                    webproducts: cartList,
                    businesslogo: businessLogo,
                    no_of_products_to_show
                })
                .then(res => {
                    return res.data;
                })
                .then(data => {
                    // console.log('saveWebProducts data', data);

                    dispatch({
                        type: actionTypes.SUCCESS_SAVE_WEB_PRODUCTS,
                        payload: {
                            id: data.id,
                            webproducts: cartList,
                            message: data.message

                        }
                    });
                    return data;
                })

                .then(() => {
                    return dispatch({
                        type: actionTypes.SAVE_WEB_PRODUCTS_LOADING,
                        payload: false
                    });
                })
                .catch(error => {
                    // console.log("saveWebProducts error", error.response || error.message);

                    return dispatch({
                        type: actionTypes.ERROR_SAVE_WEB_PRODUCTS,
                        payload: error.message || error.response || "Something Went Wrong!"

                    });
                });
        } else {
            //productExist edit list
            axios
                .put(`${baseURL}webProducts`, {
                    businessid,
                    webproducts: cartList,
                    id: productInfoId,
                    businesslogo: businessLogo,
                    no_of_products_to_show
                })
                .then(res => {
                    return res.data;
                })
                .then(data => {
                    // console.log('updateWebProducts data', data);

                    dispatch({
                        type: actionTypes.SUCCESS_SAVE_WEB_PRODUCTS,
                        payload: {
                            id: data.id,
                            webproducts: cartList,
                            message: data.message
                        }
                    });
                    return data;
                })

                .then(() => {
                    return dispatch({
                        type: actionTypes.SAVE_WEB_PRODUCTS_LOADING,
                        payload: false
                    });
                })
                .catch(error => {
                    // console.log(
                    //     "updateWebProducts error",
                    //     error.response || error.message
                    // );
                    return dispatch({
                        type: actionTypes.ERROR_SAVE_WEB_PRODUCTS,
                        payload: error.message || error.response || "Something Went Wrong!"

                    });
                });
        }
    };
};