import React from "react";

import "./index.scss";
import NoMatch from "./pages/404";
import Home from "./home";
import PageNotFound from "./pages/404";
import ProductDetail from "./pages/ProductDetail";
import SignIn from "./pages/SignIn";
import Dashboard from "./pages/Dashboard";

// import registerServiceWorker from "./registerServiceWorker";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import "babel-polyfill";
import { Provider } from "react-redux";
import store from "./store/store";
import Privacy from "./pages/privacy";
class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter history={createBrowserHistory}>
          <Switch>
            {/* <Route exact path={`${process.env.PUBLIC_URL}/`} component={App}/> */}
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/`}
              component={() => <Home />}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/ar`}
              component={() => <Home />}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/instagram`}
              component={() => <Home />}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/snap`}
              component={() => <Home />}
            />
            <Route exact path={`${process.env.PUBLIC_URL}/uniquehypekw`}>
              <Redirect to="/" />
            </Route>
            <Route
              path={`${process.env.PUBLIC_URL}/productDetail/:productId`}
              component={() => <ProductDetail />}
            />
            {/* <Route
              path={`${process.env.PUBLIC_URL}/clientwebsiterevamp/productDetail/:productId`}
              component={() => <ProductDetail />}
            />{" "} */}
            {/** Remove later */}
            <Route
              path={`${process.env.PUBLIC_URL}/uniquehypekw/productDetail/:productId`}
              component={() => <ProductDetail />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/signin`}
              component={() => <SignIn />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/dashboard`}
              component={() => <Dashboard />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/privacypolicy`}
              component={() => <Privacy />}
            />
            {/* <Route path={`${process.env.PUBLIC_URL}/home`} component={Home}/> */}
            <Route
              path={`${process.env.PUBLIC_URL}/404`}
              component={PageNotFound}
            />
            <Route component={NoMatch} />
          </Switch>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;

// registerServiceWorker();
