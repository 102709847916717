import axios from "axios";
import jwtDecode from "jwt-decode"
import { getBusinessNameFromUrl } from './campaign.action'

import actionTypes, { baseURL } from "../actionTypes";


/**
 * 
 * @param {*} email 
 * @param {*} password 
 * 
 * On Login success set instagram account profile save webtoken to local storage and redirect to dashbaord
 */
export const login = (email, password, weburl, history) => {
    return async dispatch => {
        try {
            dispatch({
                type: actionTypes.LOGIN_LOADING,
                payload: true
            })
            const response = await axios.post(`${baseURL}userLogin`, {
                email, password, weburl
            })
            // console.log('response', response);
            const data = response.data

            if (data && data.success) {
                // if login success store token to localstorage
                // and sabe account information locally
                localStorage.setItem("token", data.token);
                history.replace("/dashboard")
            } else
                if (data && !data.success) {
                    alert(data.message)
                }




        } catch (error) {
            // console.log('error login', error.response || error.message);
            history.replace("/signin")
        }

    }
}

export const checkTokenExpired = (history) => {
    return async (dispatch) => {
        try {

            const businessName = getBusinessNameFromUrl()

            // get webtoken and based on topken get information to set account details
            const token = localStorage.getItem("token")

            if (token) {
                const decodeToken = jwtDecode(token);
                if (decodeToken && decodeToken.weburl !== businessName) {
                    history.replace("/signin")
                } else {
                    // call api to check if token is valid
                    const response = await axios.get(`${baseURL}verifyAccessToken`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                    const data = response.data
                    if (data && data.success) {

                        // console.log('decodeToken', decodeToken);
                        history.replace("/dashboard")
                    }
                    else {
                        history.replace("/signin")
                        localStorage.setItem("token", null)
                    }
                }
            }
            else {
                history.replace("/signin")
            }


        } catch (error) {
            // console.log('error checkTokenExpired', error.response || error.message);

            history.replace("/signin")
        }
    }
}

export const logout = (history) => {
    return dispatch => {
        localStorage.setItem("token", null);
        history.replace("/signin")
    }
}

export const savePathName = (pathname) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SAVE_PATH_NAME,
            payload: pathname
        })
    }
}