
const privacyPolicyEn = `<div>
					<p style=text-align:center align="center">
						<strong>
							<u>PRIVACY POLICY</u>
						</strong>
					</p>
					<br />
					<p>
						This Privacy Policy describes how “client URL” (the “Site” or “we”) collects, uses, and discloses your Personal Information when you visit or make a purchase from the Site.
					</p>
					<p>
						<strong>1. </strong>
						<strong>COLLECTING PERSONAL INFORMATION</strong>
					</p>
				
					<p>
						1.1 When you visit the Site, we collect certain information about your device and your interaction with the Site. In this Privacy Policy, we refer to any information that can uniquely identify an individual (including the information below) as “Personal Information”. See the list below for more information about what Personal Information we collect and why.
					</p>
					<p> <u>  Device information </u> </p>
					<p> 
						a) <strong>Examples of Personal Information collected:</strong/>
						version of web browser, IP address, time zone, cookie information, and how you interact with the Site. </p>
<p> <strong> b) Purpose of collection:</strong> to load the Site accurately for you, and to perform analytics on Site usage to optimize our Site.</p>
<p> <strong> c) Source of collection:</strong> Collected automatically when you access our Site using cookies, log files, web beacons, tags, or pixels. </p>
<p>  <strong> d) Disclosure for a business purpose:</strong> shared with our processor Optimize and other marketing platforms for advertising purposes if needed. </p>

					<p>
						<strong>2. </strong>
						<strong>SHARING PERSONAL INFORMATION</strong>
					</p>
					<p>
						We share your Personal Information with service providers to help us provide our services and fulfill our contracts with you, as described above. For example:
					</p>
					<p> a) We use Optimize to power our online store. You can read more about how Optimize uses your Personal Information here: 	<a target=_BLANK href=https://optimizeapp.com/privacy> https://optimizeapp.com/privacy. </a></p>
 					<p> b) We may share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.</p>
					<p>
						As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you. For example:
					</p>
					<p> a) We use Google Analytics to help us understand how our customers use the Site. You can read more about how Google uses your Personal Information here: <a target=_BLANK href=https://policies.google.com/privacy?hl=en> https://policies.google.com/privacy?hl=en. </a></p>
 					<p> b) We share information about your use of the Site and your interaction with our ads on other websites with our advertising partners. We collect and share some of this information directly with our advertising partners, and in some cases through the use of cookies or other similar technologies (which you may consent to, depending on your location).</p>
					
					<p>
						<strong>3. </strong>
						<strong>USING PERSONAL INFORMATION</strong>
					</p>
					<p>
						<strong />
					</p>
					<p>
						 We use your personal Information to provide our services to you, which includes: offering products for sale and keeping you up to date on new products, services, and offers.</p>
					<p>3.1 Cookies:</p>
					<p>	A cookie is a small amount of information that’s downloaded to your computer or device when you visit our Site. We use a number of different cookies, including functional, performance, advertising, and social media or content cookies. Cookies provide information on how people use the website, for instance whether it’s their first time visiting or if they are a frequent visitor.</p>
					<p>The length of time that a cookie remains on your computer or mobile device depends on whether it is a “persistent” or “session” cookie. Session cookies last until you stop browsing and persistent cookies last until they expire or are deleted. Most of the cookies we use are persistent and will expire between 30 minutes and two years from the date they are downloaded to your device.</p>
					<p>You can control and manage cookies in various ways. Please keep in mind that removing or blocking cookies can negatively impact your user experience and parts of our website may no longer be fully accessible.</p>
					<p>Most browsers automatically accept cookies, but you can choose whether or not to accept cookies through your browser controls, often found in your browser’s “Tools” or “Preferences” menu. For more information on how to modify your browser settings or how to block, manage or filter cookies can be found in your browser’s help file or through such sites as <a target=_BLANK href=https://www.allaboutcookies.org >www.allaboutcookies.org. </a> </p>
					<p>Additionally, please note that blocking cookies may not completely prevent how we share information with third parties such as our advertising partners.</p>
					</p>
					<p>3.2 Do Not Track </p>
					<p>
					Please note that because there is no consistent industry understanding of how to respond to “Do Not Track” signals, we do not alter our data collection and usage practices when we detect such a signal from your browser.
					</p>

					
					<p>
						<strong />
					</p>
					<p>
						<strong>4. </strong>
						<strong>CHANGES</strong>
					</p>
					<p>
						<strong />
					</p>
					<p>
						We may update this Privacy Policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons.

					</p>
					<p>
						<strong />
					</p>
					<p>
						<strong>5. </strong>
						<strong>CONTACT</strong>
					</p>
					<p>
						<strong />
					</p>
					<p>
						 For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at support@optimizeapp.com
					</p>
					
				</div>
		`;
export default privacyPolicyEn;
