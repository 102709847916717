const actionTypes = {
  SET_LANGUAGE_LIST: "SET_LANGUAGE_LIST",
  SET_INSTA_ACCOUNT_DETAILS: "SET_INSTA_ACCOUNT_DETAILS",
  ERROR_INSTA_ACCOUNT_DETAILS: "ERROR_INSTA_ACCOUNT_DETAILS",
  SET_LOADING: "SET_LOADING",
  ERROR_GET_MORE_INSTAGRAM_POST: "ERROR_GET_MORE_INSTAGRAM_POST",
  GET_MORE_INSTAGRAM_POST: "GET_MORE_INSTAGRAM_POST",

  LOGIN_LOADING: "LOGIN_LOADING",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",

  SET_INSTAGRAM_POST_LOADING: "SET_INSTAGRAM_POST_LOADING",
  GET_MORE_INSTAGRAM_POST_PROFILE: "GET_MORE_INSTAGRAM_POST_PROFILE",
  ERROR_GET_MORE_INSTAGRAM_POST_PROFILE:
    "ERROR_GET_MORE_INSTAGRAM_POST_PROFILE",
  SET_INSTAGRAM_POST: "SET_INSTAGRAM_POST",
  ERROR_GET_INSTAGRAM_POST: "ERROR_GET_INSTAGRAM_POST",

  SET_HIDDEN_INSTAGRAM_POST: "SET_HIDDEN_INSTAGRAM_POST",
  ERROR_SET_HIDDEN_INSTAGRAM_POST: "ERROR_SET_HIDDEN_INSTAGRAM_POST",
  SET_LOADING_GET_WEB_PRODUCTS: "SET_LOADING_GET_WEB_PRODUCTS",

  SUCCESS_SAVE_WEB_PRODUCTS: "SUCCESS_SAVE_WEB_PRODUCTS",
  SAVE_WEB_PRODUCTS_LOADING: "SAVE_WEB_PRODUCTS_LOADING",
  ERROR_SAVE_WEB_PRODUCTS: "ERROR_SAVE_WEB_PRODUCTS",

  SAVE_PATH_NAME: "SAVE_PATH_NAME",

  UPDATE_ACCOUNT_INFO: "UPDATE_ACCOUNT_INFO",

  SET_LANGUAGE_LIST_POEDIT: "SET_LANGUAGE_LIST_POEDIT",
  SET_PRODUCT_DATA: "SET_PRODUCT_DATA",
};
export default actionTypes;

//export const baseURL = `https://optimizekwtestingserver.com/webapi/public/`;
//export const baseURL = `https://www.optimizeapp.com/webapi/public/`;
//export const baseURL = `https://api.prod.optimizeapp.com/client/`;
export const baseURL = `https://api.prod.optimizeapp.com/client/`;
