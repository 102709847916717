import React from "react";
import isStringArabic from "../store/isStringArabic";
import $ from "jquery";
import CalendarIcon from "../assets/images/svgs/google-calendar.svg";
import YoutubeIcon from "../assets/svgs/PlatformLogo/YoutubeNew.svg";
import WhatsappIcon from "../assets/svgs/PlatformLogo/WhatsappLogoNew.svg";
import MobileIcon from "../assets/svgs/PlatformLogo/PhoneNew.svg";
import SnapchatIcon from "../assets/svgs/PlatformLogo/SnapchatNew.svg";
import WebsiteIcon from "../assets/images/svgs/website.svg";
import TwitterIcon from "../assets/svgs/PlatformLogo/TweeterNew.svg";
import InstagramIcon from "../assets/svgs/PlatformLogo/InstaNew.svg";
import GoogleLocationIcon from "../assets/svgs/PlatformLogo/GoogleLocationNew.svg";
import { Container, Row, Col, Image } from "react-bootstrap";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import cloneDeep from "lodash/cloneDeep";
import { isMobile, isAndroid } from "react-device-detect";

class Footer extends React.Component {
  render() {
    const translate = this.props.translate;
    let mobileList = cloneDeep(this.props.platformList);
    mobileList = mobileList.length >= 5 ? mobileList.slice(0, 5) : mobileList;

    return (
      <div
        className="footerDivNew"
      // style={{
      //   background:
      //     !this.props.imagesList || this.props.imagesList.length === 0
      //       ? "transparent"
      //       : "#f4f4f4",
      // }}
      >
        {/* <div className="footerUpper">
          <div className="poweredBy">
            <p
              className="copyrightNew"
              style={{
                fontFamily: isStringArabic(translate["Powered by"])
                  ? "Araboto-bold"
                  : "Agrandir-Regular",
                // color:
                //   !this.props.imagesList || this.props.imagesList.length === 0
                //     ? "#FFFFFF"
                //     : "#c6c6c6",
              }}
            >
              {translate["Powered by"]}
            </p> */}
        {/* <a
              href="https://optimizeapp.onelink.me/XJRq/OAClientWeb"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h5
                id="copyrightOptimizeApp"
                // style={{
                //   color:
                //     !this.props.imagesList || this.props.imagesList.length === 0
                //       ? "#FFFFFF"
                //       : "#c6c6c6",
                // }}
              >
                OptimizeApp
              </h5>
            </a> */}
        {/* </div> */}
        {/* </div> */}

        {/* <a
          onClick={() => {
            this.props.history.push("/privacypolicy");
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <h5
            id="privacyPolicyNew"
            style={{
              fontFamily: isStringArabic(translate["Privacy Policy"])
                ? "Araboto-bold"
                : "Poppins-bold",
              color:
                !this.props.imagesList || this.props.imagesList.length === 0
                  ? "#FFFFFF"
                  : "#c6c6c6",
            }}
          >
            {translate[`Privacy Policy`]}
          </h5>
        </a> */}
        {/* </div>  */}
        <div className="mobileFooter">
          <h5
            style={{
              letterSpacing: "0px",
              color: "#27272E",
              fontFamily: isStringArabic(translate["Connect with us"])
                ? "Araboto-bold"
                : "Poppins-bold",
            }}
          >
            {translate["Connect with us"]}
          </h5>
          <div className="socialMediaButtonsMobile">
            {/* {this.props.instagramAccountInfo.youtube_handle && ( */}

            {mobileList &&
              mobileList.length > 0 &&
              mobileList.map((platform, index) => {
                return (
                  <div className="columnDiv" id={platform.id}>
                    <a
                      // href={this.props.instagramAccountInfo.youtube_handle}
                      target="_blank"
                      rel="noopener noreferrer"
                      id={platform.id}
                      onClick={() =>
                        this.props.handlePlatformClick(platform.platformName)
                      }
                    >
                      <Image
                        className="socialButtonImagesNew socialButtonLogoNew"
                        src={platform.iconName}
                      />
                    </a>

                    <p
                      className={`socialMediaName ${this.props.language === "ar" && "arabic-bold"
                        }`}
                    >
                      {translate[platform.platformName]}
                    </p>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      // </footer>
    );
  }
}

export default Footer;
