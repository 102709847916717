import React from "react";
import isStringArabic from "../store/isStringArabic";

class Footer extends React.Component {
  render() {
    const translate = this.props.translate;
    return (
      // <footer className="cpoy-right-bg">
      <div
        className="container footerDiv"
        style={{
          background:
            !this.props.imagesList || this.props.imagesList.length === 0
              ? "transparent"
              : "#f4f4f4",
        }}
      >
        <div className="row">
          {/* <div className="col-md-12 text-center">
								<div className="social-footer">
									<ul>
										<li>
											<a
												href={null}
												dangerouslySetInnerHTML={{
													__html: '<i class="fa fa-facebook" aria-hidden="true"></i>',
												}}
											/>
										</li>
										<li>
											<a
												href={null}
												dangerouslySetInnerHTML={{
													__html: '<i class="fa fa-google-plus" aria-hidden="true"></i>',
												}}
											/>
										</li>
										<li>
											<a
												href={null}
												dangerouslySetInnerHTML={{
													__html: '<i class="fa fa-twitter" aria-hidden="true"></i>',
												}}
											/>
										</li>
										<li>
											<a
												href={null}
												dangerouslySetInnerHTML={{
													__html: '<i class="fa fa-instagram" aria-hidden="true"></i>',
												}}
											/>
										</li>
										<li>
											<a
												href={null}
												dangerouslySetInnerHTML={{
													__html: '<i class="fa fa-rss" aria-hidden="true"></i>',
												}}
											/>
										</li>
									</ul>
								</div>
							</div>
							 */}
          {/* <div className="col-md-12 text-center"> */}
          <p
            // style={{  }}
            className="copyright"
            style={{
              fontFamily: isStringArabic(translate["Powered by"])
                ? "Araboto-bold"
                : "Poppins-bold",
              color:
                !this.props.imagesList || this.props.imagesList.length === 0
                  ? "#FFFFFF"
                  : "#c6c6c6",
            }}
          >
            {translate["Powered by"]}
          </p>
          <a
            href="https://optimizeapp.onelink.me/XJRq/OAClientWeb"
            // href="https://app.adjust.com/7a6vrs6?label=Optimize Shop Footer&redirect=https%3A%2F%2Fwww.optimizeapp.com%2Fdownload"
            // id="copyrightOptimizeApp"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h5
              id="copyrightOptimizeApp"
              style={{
                color:
                  !this.props.imagesList || this.props.imagesList.length === 0
                    ? "#FFFFFF"
                    : "#c6c6c6",
              }}
            >
              OptimizeApp
            </h5>
          </a>
          {/* </div> */}
        </div>

        <a
          onClick={() => {
            this.props.history.push("/privacypolicy");
          }}
          // href="https://optimizeapp.com/privacy"
          // id="copyrightOptimizeApp"
          target="_blank"
          rel="noopener noreferrer"
        >
          <h5
            id="privacyPolicy"
            style={{
              fontFamily: isStringArabic(translate["Privacy Policy"])
                ? "Araboto-bold"
                : "Poppins-bold",
              color:
                !this.props.imagesList || this.props.imagesList.length === 0
                  ? "#FFFFFF"
                  : "#c6c6c6",
            }}
          >
            {translate[`Privacy Policy`]}
          </h5>
        </a>
      </div>
      // </footer>
    );
  }
}

export default Footer;
