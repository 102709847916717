export default {
  EXPLORE: "اكتشف",
  "SHOP NOW": "!اطلب الآن",
  "BOOK NOW": "!احجز الآن",
  "VIEW MORE": "لرؤية المزيد",
  "ORDER ON WHATSAPP": "للطلب عبر الواتساب",
  Phone: "الإتصال",
  WhatsApp: "الواتساب",
  Instagram: "الانستغرام",
  Snapchat: "Snapchat",
  Twitter: "Twitter",
  Youtube: "Youtube",
  "CHECKOUT OUR INSTAGRAM": "لزيارة صفحتنا على الانستغرام",
  "Powered by": "تم التشغيل بواسطة",
  "Get yours now!": "!احصل على موقعك الآن",
  "This site is Powered by Optimize App": "تم إنشاء الموقع عبر تطبيق أوبتمايز",
  "بو نبيل": "بو نبيل",
  "SHOP ON INSTAGRAM": "تسوّق عبر الانستغرام",
  "ORDER ON INSTAGRAM": "اطلب عبر الانستغرام",
  "FOR INQUIRIES & ORDERS": "للطلب والاستفسار",
  "FOR RESERVATIONS & ORDERS": "للحجز والاستفسار",
  "CHECK OUT ON YOUTUBE": "للمزيد على اليوتيوب",
  "CHECK OUT ON INSTAGRAM": "للمزيد على الانستغرام",
  "CHECK OUT ON SNAPCHAT": "للمزيد على سناب شات",
  "Popular Items": "الأكثر مبيعاً",
  Featured: "المتصدر",
  PRICE: "السعر",
  "Contact us for prices": "تواصل معنا للتعرف على الأسعار",
  KWD: "دينار كويتي",
  AED: "درهم إماراتي",
  SAR: "ريال سعودي",
  BHD: "دينار بحريني",
  QAR: "ريال قطري",
  OMR: "ريال عماني",
  Map: "الخريطة",
  "Shopping Cart": "سلة التسوق",
  "Total :": "المجموع:",
  "Proceed to checkout": "الانتقال الى صفحة الدفع",
  "ADD TO CART": "أضف الى سلة التسوق",
  Remove: "إزالة",
  ADDRESS: "العنوان",
  AREA: "المنطقة",
  "Select Area": "اختر المنطقة",
  "PHONE NUMBER": "رقم الهاتف",
  BLOCK: "القطعة",
  STREET: "الشارع",
  AVENUE: "الجادة",
  "BUILDING/HOUSE": "المنزل\\المبنى",
  OFFICE: "المكتب",
  "Hey, it feels so light": "لا يوجد أي منتج في سلّة التسوق الخاصة بك",
  "There is nothing in your cart": "سلة التسوق فارغة",
  Calendar: "التقويم",
  "Event Reminder": "إضافة الى التقويم",
  Tiktok: "تيك توك",
  TikTok: "تيك توك",
  Facebook: "الفيسبوك",
  "Privacy Policy": "سياسة الخصوصية",
  "Connect with us": "اتصل بنا",
  "View Details": "عرض التفاصيل",
  "VIEW DETAILS": "عرض التفاصيل",
};
