import actionsTypes from "../actionTypes";
import actionTypes from "../actionTypes";

const defaultState = {
  languages: [],
  instagramAccountInfo: {
    businessid: null,
    businessName: "",
    brandName: null,
    brandProfilePic: null,
    username: null,
    id: null,
    isPrivate: null,
    isVerified: null,
    biography: null,
    followedBy: null,
    following: null,
    imagesList: [],
    telephoneNumber: null,
    whatsappLink: null,
    whatsappnumber: null,
    instagramLink: null,
    tiktokLink: null,
    errorMessage: null,
    loadingInstaDetail: false,
    snap_pixel_script: null,
    productDetail: {
      imageUrl: null,
      shortcode: null,
      imageId: null,
      thumbnail: null,
      caption: null,
      isVideo: null,
    },
    instagram_campaign_enabled: null,
    snapchat_handle: null,
    twitter_handle: null,
    insta_handle: null,
    tiktok_handle: null,
    web_heading_key_name: "SHOP NOW", // [SHOP NOW, EXPLORE, BOOK NOW]
    end_cursor: "",
    hasNextPage: false,
    products_to_hide_list: [],
    products_to_hide_id: null,
    button_icon: "cart", // button_icon : One of [phone, cart,instagram-outline]
    button_action: "whatsapp", // One of [whatsapp, call, instagram]
    button_text: "ORDER ON WHATSAPP", // One of [BOOK NOW, ORDER ON WHATSAPP, CHECK OUR INSTAGRAM]
    button_message:
      "Hi there.\nThe product looks great!\nIs it available for delivery ?",
    youtube_handle: null,
    viewMore: "VIEW MORE",
    buttonInstagram: "Instagram",
    buttonWhatsapp: "WhatsApp",
    buttonPhone: "Phone",
    buttonSnapchat: "Snapchat",
    buttonTwitter: "Twitter",
    buttonMap: "Map",
    buttonYoutube: "Youtube",
    buttonWebsite: "متجر الكتروني",
    showIconButtonNames: false,
    websitelink: null,
  },
  no_of_products_to_show: null,
};

/**
 *
 *
 * @param {*} [state=defaultState]
 * @param {*} action
 * @returns
 */
const campaignReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionsTypes.SET_LANGUAGE_LIST: {
      return {
        ...state,
        languages: action.payload,
      };
    }

    case actionsTypes.SET_INSTA_ACCOUNT_DETAILS: {
      return {
        ...state,
        no_of_products_to_show: action.payload.no_of_products_to_show,
        instagramAccountInfo: action.payload,
        loadingInstaDetail: false,
        errorMessage: null,
      };
    }
    case actionsTypes.ERROR_INSTA_ACCOUNT_DETAILS: {
      return {
        ...state,
        instagramAccountInfo: defaultState.instagramAccountInfo,
        loadingInstaDetail: false,
        errorMessage: action.payload.message,
      };
    }
    case actionTypes.UPDATE_ACCOUNT_INFO: {
      return {
        ...state,
        instagramAccountInfo: {
          ...state.instagramAccountInfo,
          brandProfilePic: action.payload.brandProfilePic,
        },
      };
    }
    case actionTypes.SET_LOADING: {
      return {
        ...state,
        loadingInstaDetail: action.payload,
      };
    }
    case actionTypes.GET_MORE_INSTAGRAM_POST: {
      var imagesList = [
        ...state.instagramAccountInfo.imagesList,
        ...action.payload.imagesList,
      ];

      // // check and remove all the hidden products
      // if (state.instagramAccountInfo.products_to_hide_id
      // 	&& state.instagramAccountInfo.products_to_hide_list
      // 	&& state.instagramAccountInfo.products_to_hide_list.length > 0) {
      // 	imagesList = imagesList.filter((image) => {
      // 		return !state.instagramAccountInfo.products_to_hide_list.includes(image.imageId)
      // 	})
      // }
      return {
        ...state,
        instagramAccountInfo: {
          ...state.instagramAccountInfo,
          ...action.payload,
          imagesList: [...imagesList],
        },
      };
    }
    case actionTypes.ERROR_GET_MORE_INSTAGRAM_POST: {
      return {
        ...state,
        instagramAccountInfo: {
          ...state.instagramAccountInfo,
          imagesList: [
            ...state.instagramAccountInfo.imagesList,
            ...action.payload.imagesList,
          ],
          ...action.payload,
        },
      };
    }
    case actionTypes.SET_PRODUCT_DATA: {
      return {
        ...state,
        instagramAccountInfo: {
          ...state.instagramAccountInfo,
          productDetail: action.payload,
        },
      };
    }

    default:
      return state;
  }
};

export default campaignReducer;
