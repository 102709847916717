import actionsTypes from '../actionTypes';

const defaultState = {
    language: 'ar',
    // translations: {
    // 	en: {
    // 		locale: 'en',
    // 		messages: {},
    // 	},
    // 	ar: {
    // 		locale: 'ar',
    // 		messages: {},
    // 	},
    // },
    translations: {},
};

/**
 *
 *
 * @param {*} [state=defaultState]
 * @param {*} action
 */
const languageReducer = (state = defaultState, action) => {
    switch (action.type) {
        case actionsTypes.SET_LANGUAGE_LIST_POEDIT: {
            return {
                ...state,
                language: action.payload.language,
                translations: action.payload.translations,
            };
        }

        default:
            return state;
    }
};

export default languageReducer;
